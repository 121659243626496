<template>
  <f7-sheet class="post-sheet product-sheet" swipe-to-close backdrop>
    <f7-page-content>
      <div v-if="postData?.ProductList && postData?.ProductList.length > 0" class="product-list-container">
        <div class="title">
          <h1>{{ $t.getTranslation("LBL_PRODUCTS") }}</h1>
        </div>
        <template v-for="(product, ind) in postData?.ProductList" :key="'ppl_' + ind">
          <ProductListCardComponent :data="product" type="select" @selected="selectProduct" />
        </template>
      </div>

      <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_PRODUCTS_EMPTY')" />
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { defineComponent, defineAsyncComponent } from "vue";
import { f7 } from "framework7-vue";
import { useStore } from "@/store";

// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";
// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";

const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));
const ProductListCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list-card" */ /* webpackMode: "lazy" */ "@/components/cards/ProductListCardComponent.vue"));

export default defineComponent({
  name: "PostProductComponent",
  components: {
    NoDataFoundComponent,
    ProductListCardComponent,
  },
  props: { postData: Object },
  setup() {
    const store = useStore();
   
    const showProductSheet = async () => {
      f7.sheet.get(".product-sheet").open();
    };

    return {
      showProductSheet,
      store
    };
  },
});
</script>

<style scoped>
.product-sheet .empty-container {
  min-height: 280px;
}
</style>
